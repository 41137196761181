<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title>
        <v-avatar>
          <v-img src="@/assets/img/sf.png"/>
        </v-avatar>
        Graffiti Detection PoC
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            @click="logoutUser"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
        app
        permanent
        dark
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="@/assets/img/sf.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Main menu
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
          nav
          dense
      >
<!--        <v-list-item :to="{ name: 'places' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-office-building</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-title>Places</v-list-item-title>-->
<!--        </v-list-item>-->

        <v-list-item :to="{ name: 'quicks' }">
          <v-list-item-icon>
            <v-icon>mdi-clock-fast</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Quicks</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <slot/>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MainLayout',
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['logoutUser']),
  },
};
</script>

<style lang="scss" scoped>

</style>
